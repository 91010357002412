<template>
  <footer class="mt-auto">
    <div class="container">
      <div class="row py-4">
        <div class="col-md-6 col-12 d-flex align-items-center justify-content-center footer__logo">
          <img src="@/assets/images/logo_full_colored.svg" alt="Nicole's Rainbows Logo" width="100%" height="100%" />
        </div>
        <div class="col-md-6 col-12 d-flex align-items-center justify-content-center mt-md-0 mt-4">
          <a href="https://www.facebook.com/Studio.za.krasota.Dagite.na.Nikol/" target="_blank" class="social__icon" name="facebook" aria-label="Visit our Facebook page">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/nicoles.rainbows/" target="_blank" class="social__icon ms-3" name="instagram" aria-label="Visit our Instagram page">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="tel:+359892904599" target="_blank" class="social__icon ms-3" aria-label="Give us a call">
            <i class="fa-solid fa-phone"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="copyright-container py-2">
      <div class="container">
        <p class="m-0">
          &#169;{{ $t("footer.copyright.main") }}<a href="https://www.linkedin.com/in/dimtodev/" target="_blank">{{ $t("footer.copyright.developer") }}</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style lang="scss">
footer {
  background-color: $darker-grey;
  font-family: "Cormorant Garamond", serif;
  .footer__logo {
    position: relative;
    img {
      width: 300px;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: $main-light;
      width: 2px;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
  .social__icon {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    color: $main-dark;
    border: 2px solid $main-dark;
    border-radius: 50%;
    text-align: center;
    transition: 0.15s all ease-in-out;
    &:hover {
      background-color: $main-dark;
      color: $darker-grey;
    }
  }
  .copyright-container {
    text-align: center;
    border-top: 2px solid;
    border-bottom: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(-45deg, $main-dark, $main-light, $main-dark) 1;
    p {
      color: $main-lighter;
      font-size: 20px;
      font-weight: 500;
      a {
        font-weight: 700;
        color: $main-dark;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  footer {
    .footer__logo {
      &::after {
        content: none;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  footer {
    .footer__logo {
      img {
        max-width: 80%;
      }
    }
    .social__icon {
      width: 35px;
      height: 35px;
      font-size: 16px;
      line-height: 35px;
    }
    .copyright-container {
      p {
        font-size: 16px;
      }
    }
  }
}
</style>
